import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchAll as fetchAssignments } from "../../../libs/redux/features/academic/assignmentsNewSlice";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import {
  fetchSingle as fetchSingleAssignmentSubmission,
  postData as postAssignmentSubmission,
  updateData as putAssignmentSubmission,
} from "../../../libs/redux/features/academic/assignmentSubmissionsNewSlice";
import usePageTitle from "../../../components/PageTitle";
import { Button, Checkbox, Col, Input, message, Row, Select, Spin } from "antd";
import PageCard from "../../../components/PageCard";
import BreadBtn from "../../../components/BreadBtn";

const { Option } = Select;

const AssignmentSubmissionForm = () => {
  const breadcrumbitems = [
    { title: "Academic" },
    {
      title: (
        <Link to="/academic/assignment-submissions">
          Assignment Submissions
        </Link>
      ),
    },
    { title: "Add Assignment Submission" },
  ];

  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector(
    (state) => state.assignmentSubmissionsNew.singleData
  );
  const isLoading = useSelector(
    (state) => state.assignmentSubmissionsNew.isLoading
  );
  const error = useSelector((state) => state.assignmentSubmissionsNew.error);

  const assingmentsData = useSelector((state) => state.assignmentsNew.data);
  const studentsData = useSelector((state) => state.students.data);

  useEffect(() => {
    if (assingmentsData.length === 0) {
      dispatch(fetchAssignments());
    }
    if (studentsData.length === 0) {
      dispatch(fetchAll());
    }
  }, [dispatch, assingmentsData.length, studentsData.length]);

  const [assignmentSubmission, setAssignmentSubmission] = useState({
    is_graded: true,
    grade: "",
    feedback: "",
    student: null,
    assignment: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleAssignmentSubmission(id));
    }
  }, [id, dispatch]);

  // useEffect(() => {
  //   if (assignmentSubmission.student) {
  //     const classId =
  //       studentsData &&
  //       studentsData.find((item) => item.id === assignmentSubmission.student);
  //     console.log("student id", assignmentSubmission.student);

  //     console.log("current class", classId);
  //   }
  // }, [studentsData, assignmentSubmission.student]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setAssignmentSubmission({
        is_graded: singleData.is_graded,
        grade: singleData.grade,
        feedback: singleData.feedback,
        student: singleData.student,
        assignment: singleData.assignment,
      });
    }
  }, [id, singleData]);

  usePageTitle(
    id ? "Assignment Submission Detail" : "Add Assignment Submission"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("payload", assignmentSubmission);
    if (id) {
      dispatch(putAssignmentSubmission({ id, payload: assignmentSubmission }));
    } else {
      dispatch(
        postAssignmentSubmission({
          payload: assignmentSubmission,
          onSuccess: () => {
            message.success("New assignment submission created successfully!");
            setAssignmentSubmission((prev) => ({
              ...prev,
              feedback: "",
              grade: "",
            }));
          },
        })
      );
    }
  };

  return (
    <div>
      <BreadBtn
        headerTitle="Add Assignment Submission"
        items={breadcrumbitems}
      />
      <PageCard
        title="Assignment submission data"
        content={
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Student</p>
                      {error && error.student ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.student})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Student"
                      optionFilterProp="children"
                      value={assignmentSubmission.student}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          student: value,
                        }))
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                      onClear={() =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          student: "",
                        }))
                      }
                      status={error && error.student ? "error" : ""}
                    >
                      {studentsData && studentsData.length > 0
                        ? studentsData.map((student) => (
                            <Option value={student.id} key={student.id}>
                              {student.user.first_name} {student.user.last_name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Assignment</p>
                      {error && error.assignment ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.assignment})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Assignment"
                      optionFilterProp="children"
                      value={assignmentSubmission.assignment}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          assignment: value,
                        }))
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                      onClear={() =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          assignment: "",
                        }))
                      }
                      status={error && error.assignment ? "error" : ""}
                    >
                      {assingmentsData && assingmentsData.length > 0
                        ? assingmentsData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.title}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Feedback</p>
                      {error && error.feedback ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.feedback})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Feedback"
                      required
                      value={assignmentSubmission.feedback}
                      onChange={(e) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          feedback: e.target.value,
                        }))
                      }
                      status={error && error.feedback ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Grade</p>
                      {error && error.grade ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.grade})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Grade"
                      required
                      value={assignmentSubmission.grade}
                      onChange={(e) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          grade: e.target.value,
                        }))
                      }
                      status={error && error.grade ? "error" : ""}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="my-3">
                    <Checkbox
                      checked={assignmentSubmission.is_graded}
                      onChange={(e) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          is_graded: e.target.checked,
                        }))
                      }
                    >
                      Is Graded ?
                    </Checkbox>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        }
      />
    </div>
  );
};

export default AssignmentSubmissionForm;
