import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll,
  postData,
} from "../../../libs/redux/features/accounting/transactionSlice";
import {
  downloadPDF,
  downloadXLS,
  renderDescription,
} from "../../../components/utility";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import DataConversion from "../../../components/DataConversion";
import TextArea from "antd/es/input/TextArea";
import { getData } from "../../../libs/axios/axiosInstance";

const { Option } = Select;
const { Search } = Input;

const Transactions = () => {
  usePageTitle("Transactions");

  const breadcrumbitems = [
    {
      title: "Accounting",
    },
    { title: "Transactions" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const transactionsData = useSelector((state) => state.transactions.data);
  const totalItems = useSelector((state) => state.transactions.totalItems);
  const isLoading = useSelector((state) => state.transactions.isLoading);
  const error = useSelector((state) => state.transactions.error);

  const [modalData, setModalData] = useState({
    transaction_type: "Fee Payment",
    reference_id: "",
    description: "",
    amount: "",
    transaction_date: "",
  });

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start_date: "",
    end_date: "",
    search: "",
    transaction_type: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.start_date !== "") {
      params.start_date = filters.start_date;
    }
    if (filters.end_date !== "") {
      params.end_date = filters.end_date;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.transaction_type !== "all") {
      params.transaction_type = filters.transaction_type;
    }

    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Transaction Type": item.transaction_type || "N/A",
    "Reference Id": item.reference_id || "N/A",
    Amount: item.amount || "N/A",
    "Transaction Date": item.transaction_date || "N/A",
    Description: item.description || "N/A",
    "Academic Year": item.academic_year || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Transaction Type",
      "Reference Id",
      "Amount",
      "Transaction Date",
      "Description",
      "Academic Year",
    ];
    downloadPDF(
      transactionsData,
      columns,
      formatTimeSlotsData,
      "Transaction Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(transactionsData, formatTimeSlotsData, "Transaction Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },

    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => renderDescription(text),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    setModalData({
      transaction_type: "Fee Payment",
      reference_id: "",
      description: "",
      amount: "",
      transaction_date: "",
    });
  };

  const handleModalOk = () => {
    const payload = {
      ...modalData,
      transaction_date:
        dayjs(modalData.transaction_date).format("YYYY-MM-DD") || null,
      academic_year: 23,
    };

    dispatch(
      postData({
        payload,
        onSuccess: () => {
          message.success("New transaction added successfully!");
          setModalData({
            transaction_type: "Fee Payment",
            reference_id: "",
            description: "",
            amount: "",
            transaction_date: "",
          });
          setAddModal(false);
        },
      })
    );
  };

  const [reportModal, setReportModal] = useState(false);
  const [reportModalData, setReportModalData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const reportModalClick = async () => {
    setReportLoading(true);
    try {
      const response = await getData(
        "accounting/admin/transactions/total-transactions-report/"
      );
      if (response.status === 200) {
        setReportModalData(response.data);
        setReportLoading(false);
        setReportModal(true);
      }
    } catch (error) {
      console.log("error getting all expenses", error.response);
      setReportLoading(false);
    }
  };

  const handleReportModalCancel = () => {
    setReportModal(false);
    setReportLoading(false);
    setReportModalData({});
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Transactions</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            className="me-3"
            size="large"
            onClick={reportModalClick}
            loading={reportLoading}
          >
            Expenses Report
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              value={filters.transaction_type}
              onChange={(value) =>
                handleFilterChange("transaction_type", value)
              }
            >
              <Option value="all">All Types</Option>
              <Option value="Fee Payment">Fee Payment</Option>
              <Option value="Salary Payment">Salary Payment</Option>
              <Option value="Expense">Expense</Option>
            </Select>
            <DatePicker
              placeholder="Start Date"
              className="w-200"
              value={filters.start_date ? dayjs(filters.start_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "start_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="End Date"
              className="w-200"
              value={filters.end_date ? dayjs(filters.end_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "end_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={transactionsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Transaction</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              Submit
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Transaction Type</p>
              {error && error.transaction_type ? (
                <p className="text-sm text-danger ms-3">
                  ({error.transaction_type})
                </p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              value={modalData.transaction_type}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  transaction_type: value,
                }))
              }
              status={error && error.transaction_type ? "error" : ""}
            >
              <Option value="Fee Payment">Fee Payment</Option>
              <Option value="Salary Payment">Salary Payment</Option>
              <Option value="Expense">Expense</Option>
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Reference Id</p>
              {error && error.reference_id ? (
                <p className="text-sm text-danger ms-3">
                  ({error.reference_id})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Reference Id"
              required
              value={modalData.reference_id}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  reference_id: e.target.value,
                }))
              }
              status={error && error.reference_id ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Amount</p>
              {error && error.amount ? (
                <p className="text-sm text-danger ms-3">({error.amount})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Amount"
              required
              value={modalData.amount}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
              status={error && error.amount ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Transaction Date</p>
              {error && error.transaction_date ? (
                <p className="text-sm text-danger ms-3">
                  ({error.transaction_date})
                </p>
              ) : (
                ""
              )}
            </div>
            <DatePicker
              className="w-100"
              size="large"
              placeholder="Transaction Date"
              required
              value={modalData.transaction_date}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  transaction_date: value,
                }))
              }
              status={error && error.transaction_date ? "error" : ""}
            />
          </div>

          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Description</p>
              {error && error.description ? (
                <p className="text-sm text-danger ms-3">
                  ({error.description})
                </p>
              ) : (
                ""
              )}
            </div>
            <TextArea
              className="w-100"
              autoSize={{ minRows: 2, maxRows: 4 }}
              placeholder="Description"
              required
              value={modalData.description}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              status={error && error.description ? "error" : ""}
            />
          </div>
        </Modal>
      )}

      {reportModal && (
        <Modal
          title={<h2 className="fs-3 mb-3">All Expenses</h2>}
          open={reportModal}
          onCancel={handleReportModalCancel}
          onOk={handleReportModalCancel}
        >
          {Object.keys(reportModalData).length > 0
            ? Object.entries(reportModalData).map(([key, value]) => (
                <p key={key}>
                  {key.replace(/_/g, " ")}: {value || "Not found"}
                </p>
              ))
            : "No data available"}
        </Modal>
      )}
    </div>
  );
};

export default Transactions;
