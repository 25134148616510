import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../../libs/redux/features/teacher-info/teacherAttendanceSlice";
import usePageTitle from "../../../components/PageTitle";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  message,
  Pagination,
  Select,
  Table,
} from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import DataConversion from "../../../components/DataConversion";
import { EyeOutlined, DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import SingleAttendanceModal from "./SingleAttendanceModal";
import { putData } from "../../../libs/axios/axiosInstance";

const { Search } = Input;
const { Option } = Select;

const TeacherAttendance = () => {
  usePageTitle("Teacher Attendance");
  const dispatch = useDispatch();
  const attendances = useSelector((state) => state.teacherAttendance.data);
  const isLoading = useSelector((state) => state.teacherAttendance.isLoading);
  const totalItems = useSelector((state) => state.teacherAttendance.totalItems);
  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [dispatch, teachersData]);

  const [filters, setFilters] = useState({
    search: "",
    limit: 10,
    offset: 0,
    date: null,
    status: "all",
    teacher: null,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };

    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.date !== null) {
      params.date = filters.date;
    }
    if (filters.status !== "all") {
      params.status = filters.status;
    }
    if (filters.teacher !== null) {
      params.teacher = filters.teacher;
    }
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [modal, setModal] = useState(false);
  const [modalTeacher, setModalTeacher] = useState({});
  const [modalData, setModalData] = useState({});
  const [modalLoading, setModalLoading] = useState(false);

  const viewAttendance = (data) => {
    const filterTeacher =
      teachersData && teachersData.find((item) => item.id === data.teacher);
    setModalTeacher(filterTeacher);

    setModalData(data);
    setModal(true);
  };

  const cancelAttendanceModal = () => {
    setModal(false);
  };

  const handleModalSubmit = async (payload) => {
    setModalLoading(true);
    try {
      const response = await putData(
        `teacher/admin/teacher-attendance/${modalData.id}/`,
        payload
      );
      if (response.status === 200) {
        setModalLoading(false);
        message.success("Attendance data successfully modified!");
      }
    } catch (error) {
      console.log("put error", error);
      setModalLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (record) => dayjs(record).format("DD/MM/YYYY | HH:mm"),
    },
    {
      title: "Attendance Type",
      dataIndex: "attendance_type",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Teacher",
      dataIndex: "teacher_name",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button
            className="me-4"
            size="small"
            onClick={() => viewAttendance(record)}
          >
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.teacher_name}`,
                dispatch,
                `teacher/admin/teacher-attendance/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Date: item.date || "N/A",
    "Attendance Type": item.attendance_type || "N/A",
    Status: item.status || "N/A",
    Teacher: item.teacher_name || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Date", "Attendance Type", "Status", "Teacher"];
    downloadPDF(
      attendances,
      columns,
      formatTimeSlotsData,
      "Teacher Attendance Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(attendances, formatTimeSlotsData, "Teacher Attendance Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Teacher Attendance</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    {" "}
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Teachers Info" },
              { title: "Teacher Attendance" },
            ]}
          />
        </div>
        <div className="">
          <Link to="/teachers-info/teacher-attendance/add-attendance">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Bulk Attendance
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
              onClear={() => handleFilterChange("search", "")}
            />
            <DatePicker
              className="w-200"
              placeholder="Start date"
              value={filters.date ? dayjs(filters.date) : null}
              onChange={(date) =>
                handleFilterChange(
                  "date",
                  date ? date.format("YYYY-MM-DD") : null
                )
              }
              allowClear
              onClear={() => handleFilterChange("date", null)}
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("status", value)}
            >
              <Option value="all">Filter by All Status</Option>
              <Option value="Present">Present</Option>
              <Option value="Absent">Absent</Option>
              <Option value="Late">Late</Option>
              <Option value="Excused">Excused</Option>
            </Select>
            <Select
              showSearch
              className="w-200"
              value={filters.teacher || null}
              onChange={(value) => handleFilterChange("teacher", value)}
              placeholder="Select Teacher"
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => handleFilterChange("teacher", null)}
            >
              {teachersData &&
                teachersData.map((item) => (
                  <Option
                    value={item.id}
                    key={item.id}
                    label={`${item.user.first_name} ${item.user.last_name}`}
                  >
                    {item.user.first_name} {item.user.last_name}
                  </Option>
                ))}
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={attendances}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* single attendance */}
      {modal && (
        <SingleAttendanceModal
          visible={modal}
          onCancel={cancelAttendanceModal}
          onSubmit={handleModalSubmit}
          teacher={modalTeacher}
          initialAttendance={{
            date: modalData.date,
            attendance_type: modalData.attendance_type,
            status: modalData.status,
            notes: modalData.notes,
            teacher: modalData.teacher,
          }}
          loading={modalLoading}
        />
      )}
    </div>
  );
};

export default TeacherAttendance;
