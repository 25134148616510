import React, { useState } from "react";
import { Badge, Button, Col, Row, Segmented } from "antd";
import usePageTitle from "../../components/PageTitle";
import { GiTeacher } from "react-icons/gi";
import { PiStudentFill, PiTrafficSignBold, PiExamFill } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdWebStories } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  CardSm,
  EarningsChart,
  earningsData,
  ExpensesChart,
  expensesData,
  QuickLinkCard,
} from "./charts";
import { IoCalendarSharp } from "react-icons/io5";
import { FaPersonWalkingLuggage } from "react-icons/fa6";

const Homepage = () => {
  usePageTitle("Home");
  const [selectedChart, setSelectedChart] = useState("Earnings");

  const renderChart = () => {
    if (selectedChart === "Earnings") {
      return <EarningsChart data={earningsData} />;
    } else {
      return <ExpensesChart data={expensesData} />;
    }
  };

  return (
    <div>
      <div className="mt-5">
        <Row gutter={16} className="mb-4">
          <Col span={24} md={16}>
            <Row className="bg-white rounded-10 p-2 mb-2 shadow-sm-2">
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm Icon={GiTeacher} title={"Total Teachers"} value={70} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={PiStudentFill}
                  title={"Total Students"}
                  value={300}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm Icon={FaUsers} title={"Total Staffs"} value={12} />
              </Col>
            </Row>
            <Row className="bg-white rounded-10 p-2 shadow-sm-2">
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={CgWebsite}
                  title={"Website Visitor Count"}
                  value={200}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={MdWebStories}
                  title={"Page View Count"}
                  value={200}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <CardSm
                  Icon={PiTrafficSignBold}
                  title={"Average Traffic"}
                  value={20}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} md={8}>
            <div className="bg-dark-2 text-white p-3 h-100 rounded-10 flex flex-column aic">
              <p className="fw-7">Today's Student Attendance</p>
              <p className="fs-1 fw-6 text-center">0</p>
              <Button className="border-0 rounded-10 text-dark">
                <Link to="/student-data/student-attendances">
                  Goto Student Attendance
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-4">
          <Col span={24} md={16}>
            <div className="bg-white p-3 rounded-10">
              <div className="w-100 flex justify-center">
                <Segmented
                  options={["Earnings", "Expenses"]}
                  onChange={(value) => setSelectedChart(value)}
                  defaultValue="Earnings"
                />
              </div>
              <div className="mt-2" style={{ minHeight: 360 }}>
                {renderChart()}
              </div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <Badge.Ribbon text="Quick Links" placement="start">
              <div className="bg-white p-4 pb-0 h-100 rounded-10 shadow-sm-2">
                <div className="p-2 mt-4">
                  <Row gutter={16}>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/academic/calendars"
                        Icon={IoCalendarSharp}
                        title="Calendars"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/payroll-info/leave-requests"
                        Icon={FaPersonWalkingLuggage}
                        title="Leave Requests"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/exam-data/exam-results"
                        Icon={PiExamFill}
                        title="Exam Results"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/academic/calendars"
                        Icon={IoCalendarSharp}
                        title="Calendars"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/payroll-info/leave-requests"
                        Icon={FaPersonWalkingLuggage}
                        title="Leave Requests"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/exam-data/exam-results"
                        Icon={PiExamFill}
                        title="Exam Results"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/academic/calendars"
                        Icon={IoCalendarSharp}
                        title="Calendars"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/payroll-info/leave-requests"
                        Icon={FaPersonWalkingLuggage}
                        title="Leave Requests"
                      />
                    </Col>
                    <Col span={8} className="mb-3">
                      <QuickLinkCard
                        path="/exam-data/exam-results"
                        Icon={PiExamFill}
                        title="Exam Results"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Badge.Ribbon>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={16}>
            <Row gutter={16}>
              <Col span={24} md={8}>
                <div className="bg-secondary text-dark p-3 rounded-10 flex flex-column aic shadow-sm-2">
                  <p className="fw-7">Total Fees Payment</p>
                  <p className="fs-1 fw-6 text-center">0</p>
                  <Button className="rounded-10">+ New Payment</Button>
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className="bg-secondary text-dark p-3 rounded-10 flex flex-column aic shadow-sm-2">
                  <p className="fw-7">Total Expenses</p>
                  <p className="fs-1 fw-6 text-center">0</p>
                  <Button className="rounded-10">+ New Expense</Button>
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className="bg-secondary text-dark p-3 rounded-10 flex flex-column aic shadow-sm-2">
                  <p className="fw-7">Total Transactions</p>
                  <p className="fs-1 fw-6 text-center">0</p>
                  <Button className="rounded-10">+ New Transaction</Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={8}>
            <div className="bg-dark-2 text-white p-3 h-100 rounded-10 flex flex-column aic">
              <p className="fw-7">Today's Teacher Attendance</p>
              <p className="fs-1 fw-6 text-center">0</p>
              <Button className="border-0 rounded-10 text-dark">
                <Link to="/teachers-info/teacher-attendance">
                  Goto Teacher Attendance
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div></div>
    </div>
  );
};

export default Homepage;
