import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import UploadButton from "../../../components/UploadButton";
import ImagePopup from "../../../components/ImagePopup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleCareerItem,
  postCareer,
  putCareer,
} from "../../../libs/redux/features/website/careerSlice";
import usePageTitle from "../../../components/PageTitle";
import useScrollEffect from "../../../components/scroEffect";

const CareerForm = () => {
  const isAtTop = useScrollEffect();

  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    isLoading,
    singleData,
    error: errors,
  } = useSelector((state) => state.career);
  const [career, setCareer] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    slug: "",
    job_title: "",
    job_type: "",
    location: "",
    experience_required: "",
    working_hours: "",
    working_days: "",
    salary: "",
    vacancy: 0,
    deadline: "",
    job_description: "",
    is_active: true,
    featured_image: {},
  });

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleCareerItem(id));
    }
  }, [id, dispatch]);

  usePageTitle(!isNaN(id) ? `${singleData.job_title}` : "Add Career");

  useEffect(() => {
    if (!isNaN(id) && Object.keys(singleData).length > 0 && singleData.seo) {
      setCareer(singleData);
    }
  }, [singleData, id]);

  const [featuredImageModal, setFeaturedImageModal] = useState(false);

  const handleFeaturedImageUploadSuccess = (newImage) => {
    setCareer((prev) => ({
      ...prev,
      featured_image: newImage[0],
    }));
    setFeaturedImageModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      let payload = { ...career, featured_image: career.featured_image.id };
      dispatch(putCareer({ id, payload }));
    } else {
      const image = career.featured_image.id;
      if (image) {
        let payload = { ...career, featured_image: image };

        dispatch(
          postCareer({
            payload,
            onSuccess: () => {
              setCareer({
                seo: {
                  meta_title: "",
                  meta_description: "",
                  meta_keywords: "",
                },
                slug: "",
                job_title: "",
                job_type: "",
                location: "",
                experience_required: "",
                working_hours: "",
                working_days: "",
                salary: "",
                vacancy: 0,
                deadline: "",
                job_description: "",
                is_active: true,
                featured_image: {},
              });
            },
          })
        );
      } else {
        message.error("No Featured Image detected");
      }
    }
  };

  return (
    <div>
      <div
        className={`breadcrumb flex-justify-between ${
          !isAtTop ? "sticky" : ""
        }`}
      >
        <div>
          <h1 className={`fs-2 text-dark ${!isAtTop ? "lh-1" : ""}`}>
            {!isNaN(id) ? `${singleData.job_title}` : "Add Career"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Website",
              },
              {
                title: "Components",
              },
              {
                title: <Link to="/website/components/careers">Careers</Link>,
              },
              {
                title: !isNaN(id) ? `${singleData.job_title}` : "Add Career",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={career.seo.meta_title || ""}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={career.seo.meta_description || ""}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        * Meta Keywords (comma ',' separated value)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={career.seo.meta_keywords || ""}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Slug</p>
                        {errors && errors.slug ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.slug})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Slug"
                        value={career.slug}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            slug: e.target.value,
                          }))
                        }
                        status={errors && errors.slug ? "error" : ""}
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Job Title</p>
                      <Input
                        className="w-100"
                        placeholder="Job Title"
                        size="large"
                        value={career.job_title}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            job_title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Job Type</p>
                        {errors && errors.job_type ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.job_type})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Job Type"
                        value={career.job_type}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            job_type: e.target.value,
                          }))
                        }
                        status={errors && errors.job_type ? "error" : ""}
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Location</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Location"
                        value={career.location}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            location: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Experience Required</p>
                        {errors && errors.experience_required ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.experience_required})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Experience Required"
                        value={career.experience_required}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            experience_required: e.target.value,
                          }))
                        }
                        status={
                          errors && errors.experience_required ? "error" : ""
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Working Hours</p>
                        {errors && errors.working_hours ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.working_hours})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Working Hours"
                        value={career.working_hours}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            working_hours: e.target.value,
                          }))
                        }
                        status={errors && errors.working_hours ? "error" : ""}
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Working Days</p>
                        {errors && errors.working_days ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.working_days})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Working Days"
                        value={career.working_days}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            working_days: e.target.value,
                          }))
                        }
                        required
                        status={errors && errors.working_days ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6"></p>
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Salary</p>
                        {errors && errors.salary ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.salary})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Salary"
                        value={career.salary}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            salary: e.target.value,
                          }))
                        }
                        status={errors && errors.salary ? "error" : ""}
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Vacancy</p>
                        {errors && errors.vacancy ? (
                          <p className="text-sm text-danger ms-3">
                            ({errors.vacancy})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Vacancy"
                        value={career.vacancy}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            vacancy: e.target.value,
                          }))
                        }
                        status={errors && errors.vacancy ? "error" : ""}
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Deadline</p>
                      <DatePicker
                        size="large"
                        className="w-100"
                        value={career.deadline ? dayjs(career.deadline) : null}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            deadline: e ? dayjs(e).format("YYYY-MM-DD") : "",
                          }))
                        }
                        status={errors && errors.deadline ? "error" : ""}
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Job Description</p>
                      <JoditEditor
                        className="rounded-5"
                        style={{ maxHeight: "50vh" }}
                        value={career.job_description}
                        onBlur={(value) =>
                          setCareer((prev) => ({
                            ...prev,
                            job_description: value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <p className="mb-2 ps-2 fw-6">* Featured Image</p>
                      <div className="image-preview-grid">
                        {career.featured_image &&
                        Object.keys(career.featured_image).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={career.featured_image.alt_text || ""}
                            src={career.featured_image.image || ""}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setFeaturedImageModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <Checkbox
                        checked={career.is_active}
                        onChange={(e) =>
                          setCareer((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
        {featuredImageModal && (
          <ImagePopup
            open={featuredImageModal}
            onCancel={() => setFeaturedImageModal(false)}
            onUploadSuccess={handleFeaturedImageUploadSuccess}
            cropAspectRatio={16 / 9}
            imageTitle={`${career.job_title} - featured image`}
          />
        )}
      </div>
    </div>
  );
};

export default CareerForm;
