import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchPaySchedules } from "../../../libs/redux/features/payroll/paySchedulesSlice";
import { fetchAll as fetchUsers } from "../../../libs/redux/features/users/usersSlice";
import {
  fetchAll as fetchPayrolls,
  postData,
  updateData,
} from "../../../libs/redux/features/payroll/payrollsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import dayjs from "dayjs";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import { RiAiGenerate } from "react-icons/ri";
import { postData as postSalarySlip } from "../../../libs/axios/axiosInstance";
import { Link } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

const Payrolls = () => {
  usePageTitle("Payrolls");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Payroll Info",
    },
    { title: "Payrolls" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const payrollsData = useSelector((state) => state.payrolls.data);
  const totalItems = useSelector((state) => state.payrolls.totalItems);
  const isLoading = useSelector((state) => state.payrolls.isLoading);
  const error = useSelector((state) => state.payrolls.error);

  const paySchedulesData = useSelector((state) => state.paySchedules.data);
  const usersData = useSelector((state) => state.users.data);

  const [modalData, setModalData] = useState({
    salary_month: "",
    base_salary: "",
    bonuses: "",
    deductions: "",
    overtime_hours: "",
    overtime_rate: "",
    user: null,
    pay_schedule: null,
  });

  useEffect(() => {
    if (paySchedulesData.length === 0) {
      dispatch(fetchPaySchedules());
    }
    dispatch(fetchUsers({ is_staff: true }));
  }, [dispatch, paySchedulesData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start_date: "",
    end_date: "",
    search: "",
    pay_schedule: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.start_date !== "") {
      params.start_date = filters.start_date;
    }
    if (filters.end_date !== "") {
      params.end_date = filters.end_date;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.pay_schedule !== "all") {
      params.pay_schedule = filters.pay_schedule;
    }

    dispatch(fetchPayrolls(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const userInfo =
      usersData && usersData.find((entry) => entry.id === item.user);
    const payInfo =
      paySchedulesData &&
      paySchedulesData.find((entry) => entry.id === item.pay_schedule)?.name;
    return {
      Serial: index + 1,
      User:
        userInfo && userInfo
          ? `${userInfo.first_name} ${userInfo.last_name}`
          : "N/A",
      "Salary Month": item.salary_month || "N/A",
      "Base Salary": item.base_salary || "N/A",
      "Total Salary": item.total_salary || "N/A",
      Bonuses: item.bonuses || "N/A",
      Deductions: item.deductions || "N/A",
      "Overtime hours": item.overtime_hours || "N/A",
      "Overtime Rate": item.overtime_rate || "N/A",
      "Pay Schedule": payInfo || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "User",
      "Salary Month",
      "Base Salary",
      "Total Salary",
      "Bonuses",
      "Deductions",
      "Overtime hours",
      "Overtime Rate",
      "Pay Schedule",
    ];
    downloadPDF(payrollsData, columns, formatTimeSlotsData, "Payroll Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(payrollsData, formatTimeSlotsData, "Payroll Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      salary_month: data.salary_month
        ? dayjs(data.salary_month).format("YYYY-MM-DD")
        : null,
      base_salary: data.base_salary,
      bonuses: data.bonuses,
      deductions: data.deductions,
      overtime_hours: data.overtime_hours,
      overtime_rate: data.overtime_rate,
      user: data.user,
      pay_schedule: data.pay_schedule || null,
    });
    setId(data.id);
    setAddModal(true);
  };

  const [salarySlipLoading, setSalarySlipLoading] = useState(null);
  const [salarySlipData, setSalarySlipData] = useState({});
  const [salarySlipModal, setSalarySlipModal] = useState(false);

  const generateSalarySlip = async (id) => {
    setSalarySlipLoading(id);
    try {
      const response = await postSalarySlip(
        `payroll/admin/payrolls/${id}/generate-salary-slip/`
      );
      if (response.status === 201) {
        setSalarySlipData(response.data);
        setSalarySlipModal(true);
      }
    } catch (error) {
      if (error && error.message) {
        message.error(error.message);
      }
    } finally {
      setSalarySlipLoading(null);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "User",
      dataIndex: "user",
      render: (user) => {
        const userInfo =
          usersData && usersData.find((item) => item.id === user);
        return userInfo
          ? `${userInfo.first_name} ${userInfo.last_name}`
          : "Unknown";
      },
    },
    {
      title: "Salary Month",
      dataIndex: "salary_month",
    },
    {
      title: "Base Salary",
      dataIndex: "base_salary",
    },
    {
      title: "Total Salary",
      dataIndex: "total_salary",
    },
    {
      title: "Overtime Hours",
      dataIndex: "overtime_hours",
    },
    {
      title: "Overtime Rate",
      dataIndex: "overtime_rate",
    },
    {
      title: "Pay Schedule",
      dataIndex: "pay_schedule",
      render: (pay) =>
        (paySchedulesData &&
          paySchedulesData.find((item) => item.id === pay)?.name) ||
        "Unknown",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      render: (_, record) => (
        <div className="flex aic">
          <Tooltip placement="bottomLeft" title={"Generate Salary Slip"}>
            <Button
              loading={salarySlipLoading === record.id}
              variant="dashed"
              className="me-4 p-2"
              onClick={() => generateSalarySlip(record.id)}
            >
              <RiAiGenerate />
            </Button>
          </Tooltip>
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.user}`,
                dispatch,
                `payroll/admin/payrolls/${record.id}/`,
                fetchPayrolls,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData((prev) => ({
      ...prev,
      salary_month: "",
      base_salary: "",
      bonuses: "",
      deductions: "",
      overtime_hours: "",
      overtime_rate: "",
    }));
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New payroll added successfully!");
            handleModalCancel();
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Payrolls</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("pay_schedule", value)}
            >
              <Option value="all">All Pay Schedules</Option>
              {paySchedulesData && paySchedulesData.length > 0
                ? paySchedulesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <DatePicker
              placeholder="Start Date"
              className="w-200"
              value={filters.start_date ? dayjs(filters.start_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "start_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="End Date"
              className="w-200"
              value={filters.end_date ? dayjs(filters.end_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "end_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={payrollsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Payroll Form</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <Row className="responsive-row">
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* User</p>
                  {error && error.user ? (
                    <p className="text-sm text-danger ms-3">({error.user})</p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  showSearch
                  className="w-100"
                  size="large"
                  placeholder="Select User"
                  optionFilterProp="children"
                  value={modalData.user || null}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      user: value,
                    }))
                  }
                  allowClear
                  onClear={() =>
                    setModalData((prev) => ({
                      ...prev,
                      user: "",
                    }))
                  }
                  status={error && error.user ? "error" : ""}
                >
                  {usersData && usersData.length > 0
                    ? usersData.map((student) => (
                        <Option value={student.id} key={student.id}>
                          {student.first_name} {student.last_name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Pay Schedule</p>
                  {error && error.pay_schedule ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.pay_schedule})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  showSearch
                  className="w-100"
                  size="large"
                  placeholder="Select Course"
                  optionFilterProp="children"
                  value={modalData.pay_schedule || null}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      pay_schedule: value,
                    }))
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  onClear={() =>
                    setModalData((prev) => ({
                      ...prev,
                      pay_schedule: null,
                    }))
                  }
                  status={error && error.pay_schedule ? "error" : ""}
                >
                  {paySchedulesData && paySchedulesData.length > 0
                    ? paySchedulesData.map((student) => (
                        <Option value={student.id} key={student.id}>
                          {student.name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Salary Month</p>
                  {error && error.salary_month ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.salary_month})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <DatePicker
                  className="w-100"
                  placeholder="Date"
                  required
                  value={
                    modalData.salary_month
                      ? dayjs(modalData.salary_month)
                      : null
                  }
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      salary_month: e ? dayjs(e).format("YYYY-MM-DD") : null,
                    }))
                  }
                  status={error && error.salary_month ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Base Salary</p>
                  {error && error.base_salary ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.base_salary})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Base Salary"
                  required
                  value={modalData.base_salary}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      base_salary: e.target.value,
                    }))
                  }
                  status={error && error.base_salary ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Bonuses</p>
                  {error && error.bonuses ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.bonuses})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Bonuses"
                  required
                  value={modalData.bonuses}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      bonuses: e.target.value,
                    }))
                  }
                  status={error && error.bonuses ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Deductions</p>
                  {error && error.deductions ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.deductions})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Deductions"
                  required
                  value={modalData.deductions}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      deductions: e.target.value,
                    }))
                  }
                  status={error && error.deductions ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Overtime Hours</p>
                  {error && error.overtime_hours ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.overtime_hours})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Overtime Hours"
                  required
                  value={modalData.overtime_hours}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      overtime_hours: e.target.value,
                    }))
                  }
                  status={error && error.overtime_hours ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} sm={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Overtime Rate</p>
                  {error && error.overtime_rate ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.overtime_rate})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Overtime Rate"
                  required
                  value={modalData.overtime_rate}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      overtime_rate: e.target.value,
                    }))
                  }
                  status={error && error.overtime_rate ? "error" : ""}
                />
              </div>
            </Col>
          </Row>
        </Modal>
      )}

      {salarySlipModal && (
        <Modal
          open={salarySlipModal}
          onCancel={() => setSalarySlipModal(false)}
          onOk={() => setSalarySlipModal(false)}
        >
          {Object.keys(salarySlipData).length > 0
            ? Object.entries(salarySlipData).map(([key, value]) => (
                <p key={key}>
                  {key.replace(/_/g, " ")}: {value || "Not found"}
                </p>
              ))
            : "No Data Available"}
        </Modal>
      )}
    </div>
  );
};

export default Payrolls;
