import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Checkbox,
  DatePicker,
  Spin,
  Button,
  message,
  Image,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersSlice";
import BreadBtn from "../../../components/BreadBtn";
import PageCard from "../../../components/PageCard";
import dayjs from "dayjs";
import { postData } from "../../../libs/axios/axiosInstance";
import SingleAttendanceModal from "./SingleAttendanceModal";

const TeacherAttendanceForm = () => {
  const breadcrumbItems = [
    { title: "Teachers Info" },
    {
      title: (
        <Link to="/teachers-info/teacher-attendance">Teacher Attendance</Link>
      ),
    },
    { title: "Add Attendance" },
  ];

  const dispatch = useDispatch();
  const { data: teachersData, isLoading } = useSelector(
    (state) => state.teachers
  );

  const [selectedDate, setSelectedDate] = useState(dayjs()); // Default date is today
  const [attendance, setAttendance] = useState([]);

  // On component mount, fetch teachers and initialize attendance
  useEffect(() => {
    dispatch(fetchTeachers());
  }, [dispatch]);

  useEffect(() => {
    if (teachersData) {
      // Initialize attendance state for each teacher
      const initialAttendance = teachersData.map((teacher) => ({
        teacher: teacher.id,
        date: selectedDate, // Use selected date for all teachers
        attendance_type: "Full Day",
        status: "Present", // Default to Present
        notes: "",
        is_finalized: true,
      }));
      setAttendance(initialAttendance);
    }
  }, [teachersData, selectedDate]);

  console.log("teacherAttendance", attendance);

  // Submit the attendance for all teachers
  const handleSubmit = async () => {
    const formattedAttendance = attendance.map((entry) => ({
      ...entry,
      date: dayjs(entry.date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"), // Format date on submission
    }));

    try {
      const response = await postData(
        "teacher/admin/teacher-attendance/bulk-create/",
        formattedAttendance
      );
      console.log("add attendance response", response);
      if (response.status === 201) {
        message.success(
          `Teacher attendance submitted for ${selectedDate.format(
            "YYYY-MM-DD"
          )} successfully!`
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // Extract the specific error messages
        const errors = error.response.data;

        // Check for the "non_field_errors" message in any of the attendance entries
        const duplicateErrors = errors.filter(
          (entry) => entry.non_field_errors && entry.non_field_errors.length > 0
        );

        if (duplicateErrors.length > 0) {
          // Handle the duplicate error by showing a message
          message.error(
            "Duplicate attendance records found for some teachers!"
          );
          console.log("Duplicate attendance error: ", duplicateErrors);
        } else {
          // Handle other types of errors
          message.error("An error occurred while submitting attendance.");
          console.log("Other errors: ", error.response.data);
        }
      } else {
        message.error("An unexpected error occurred.");
        console.log("Unexpected error: ", error);
      }
    }
  };

  const [attendanceModal, setAttendanceModal] = useState(false);
  const [modalTeaher, setModalTeacher] = useState({});
  const [modalLoading, setModalLoading] = useState(false);

  const handleAttendanceModalData = (teacher) => {
    setModalTeacher(teacher);
    setAttendanceModal(true);
  };

  const cancelAttendanceModal = () => {
    setAttendanceModal(false);
  };

  // Handle DatePicker change
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date
    // Update the date in attendance array
    const updatedAttendance = attendance.map((entry) => ({
      ...entry,
      date: date,
    }));
    setAttendance(updatedAttendance);
  };

  // Handle checkbox change
  const handleCheckboxChange = (teacherId, checked) => {
    // Update attendance based on checkbox state (Present or Absent)
    const updatedAttendance = attendance.map((entry) =>
      entry.teacher === teacherId
        ? { ...entry, status: checked ? "Present" : "Absent" }
        : entry
    );
    setAttendance(updatedAttendance);
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      width: 50,
      render: (_, teacher) => (
        <Checkbox
          defaultChecked
          onChange={(e) => handleCheckboxChange(teacher.id, e.target.checked)}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "user",
      width: 200,
      render: (user) => (
        <div className="flex aic">
          <Image
            src={user.image.image}
            alt={user.image.alt_text}
            style={{
              width: 50,
              height: 50,
              borderRadius: 10,
              marginRight: 10,
            }}
          />
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "Single Attendance",
      dataIndex: "single_attendance",
      key: "single_attendance",
      render: (_, teacher) => (
        <Button
          size="small"
          type="primary"
          onClick={() => handleAttendanceModalData(teacher)}
        >
          Single Attendance
        </Button>
      ),
    },
  ];

  const handleModalSubmit = async (payload) => {
    setModalLoading(true);
    try {
      const response = await postData(
        "teacher/admin/teacher-attendance/",
        payload
      );
      if (response.status === 201) {
        setModalLoading(false);
        message.success("Attendance added successfully!");
      }
    } catch (error) {
      console.log("error single attendance", error);
      setModalLoading(false);
    }
  };

  return (
    <div>
      <BreadBtn headerTitle="Add Attendance" items={breadcrumbItems} />
      <div className="page-content-section">
        <Spin spinning={isLoading}>
          <PageCard
            title="Attendance Form"
            content={
              <>
                <DatePicker
                  placeholder="Attendance Date"
                  className="w-200 mb-3"
                  value={selectedDate} // Default to today's date
                  onChange={handleDateChange} // Update attendance dates on change
                  required
                />
                <Table
                  dataSource={teachersData}
                  columns={columns}
                  rowKey={(record) => record.id}
                  pagination={false}
                  scroll={{ x: 900 }}
                />
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  style={{ marginTop: "20px" }}
                  onClick={handleSubmit}
                >
                  Submit Attendance
                </Button>
              </>
            }
          />
        </Spin>
      </div>
      {/* single attendance modal */}
      {attendanceModal && (
        <SingleAttendanceModal
          visible={attendanceModal}
          onCancel={cancelAttendanceModal}
          onSubmit={handleModalSubmit}
          teacher={modalTeaher}
          initialAttendance={{
            date: selectedDate,
            attendance_type: "Full Day",
            status: "Present",
            notes: "",
            teacher: modalTeaher.id,
          }}
          loading={modalLoading}
        />
      )}
    </div>
  );
};

export default TeacherAttendanceForm;
