import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import { Link, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingle as fetchSingleClassItem,
  postData as postClass,
  updateData as putClass,
} from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";

const { Option } = Select;

const ClassesForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.classesNew.singleData);
  const isLoading = useSelector((state) => state.classesNew.isLoading);
  const error = useSelector((state) => state.classesNew.error);

  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [dispatch, teachersData.length]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleClassItem(id));
    }
  }, [id, dispatch]);

  const [classData, setClassData] = useState({
    name: "",
    level: null,
    class_teacher: null,
    description: "",
    has_sections: false,
    sections: [
      {
        name: "",
        capacity: "",
        description: "",
        is_active: true,
      },
    ],
    is_active: true,
  });

  useEffect(() => {
    if (teachersData.length > 0) {
      setClassData((prev) => ({
        ...prev,
        class_teacher: teachersData[0].id,
      }));
    }
  }, [teachersData]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setClassData({
        name: singleData.name,
        level: singleData.level,
        class_teacher: singleData.class_teacher || null,
        description: singleData.description,
        has_sections: singleData.has_sections,
        sections:
          singleData.sections?.map((item) => ({
            name: item.name,
            capacity: item.capacity,
            description: item.description,
            is_active: item.is_active,
          })) || [],
        is_active: singleData.is_active || true,
      });
    }
  }, [id, singleData]);

  usePageTitle(id ? singleData.name : "Add Class");

  const handleAddSection = () => {
    setClassData((prev) => ({
      ...prev,
      sections: [
        ...prev.sections,
        { name: "", capacity: "", description: "", is_active: true },
      ],
    }));
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = classData.sections.map((section, i) =>
      i === index
        ? { ...section, [field]: field === "capacity" ? Number(value) : value }
        : section
    );
    setClassData((prev) => ({
      ...prev,
      sections: updatedSections,
    }));
  };

  const handleRemoveSection = (index) => {
    const updatedSections = classData.sections.filter((_, i) => i !== index);
    setClassData((prev) => ({
      ...prev,
      sections: updatedSections,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = classData;
    if (classData.has_sections === false) {
      payload.sections = [];
    }

    if (!id) {
      dispatch(
        postClass({
          payload,
          onSuccess: () => {
            message.success("New class created successfully!");
            setClassData({
              name: "",
              level: null,
              description: "",
              class_teacher: null,
              has_sections: false,
              sections: [
                {
                  name: "",
                  capacity: "",
                  description: "",
                  is_active: true,
                },
              ],
              is_active: true,
            });
          },
        })
      );
    } else {
      dispatch(putClass({ id, payload }));
    }
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? singleData.name : "Add Class"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Academic",
              },
              {
                title: <Link to="/academic/classes">Classes</Link>,
              },
              {
                title: id ? singleData.name : "Add Class",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Class Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Name</p>
                        {error && error.name ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.name})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={classData.name}
                        onChange={(e) =>
                          setClassData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        status={error && error.name ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Level</p>
                        {error && error.level ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.level})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        type="number"
                        placeholder="Level"
                        required
                        value={classData.level}
                        onChange={(e) =>
                          setClassData((prev) => ({
                            ...prev,
                            level: Number(e.target.value),
                          }))
                        }
                        status={error && error.level ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">Class Teacher</p>
                        {error && error.class_teacher ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_teacher})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        className="w-100"
                        size="large"
                        placeholder="Select User"
                        optionFilterProp="children"
                        value={classData.class_teacher || null}
                        onChange={(value) =>
                          classData((prev) => ({
                            ...prev,
                            class_teacher: value,
                          }))
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                        onClear={() =>
                          setClassData((prev) => ({
                            ...prev,
                            class_teacher: null,
                          }))
                        }
                        status={error && error.class_teacher ? "error" : ""}
                      >
                        {teachersData && teachersData.length > 0
                          ? teachersData.map((teacher) => (
                              <Option value={teacher.id} key={teacher.id}>
                                {teacher.user.first_name}{" "}
                                {teacher.user.last_name}
                              </Option>
                            ))
                          : ""}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Description</p>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={classData.description}
                        onChange={(e) =>
                          setClassData((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={classData.has_sections}
                        onChange={(e) =>
                          setClassData((prev) => ({
                            ...prev,
                            has_sections: e.target.checked,
                          }))
                        }
                      >
                        Has Sections ?
                      </Checkbox>
                    </div>
                  </Col>
                  {classData.has_sections && (
                    <Col span={24}>
                      <div className="p-3 border-mute rounded-10 sections-info">
                        <div className="mb-2">
                          {error && error.non_field_errors?.[0] ? (
                            <p className="text-sm text-danger ms-3">
                              ({error.non_field_errors?.[0]})
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {classData.sections.map((section, index) => (
                          <Row
                            gutter={12}
                            className="aic mb-3 border-b-r g-3-r"
                            key={index}
                          >
                            <Col span={24} md={6}>
                              <div>
                                <Input
                                  className="w-100"
                                  size="large"
                                  placeholder="Name"
                                  value={section.name}
                                  onChange={(e) =>
                                    handleSectionChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col span={24} md={6}>
                              <div>
                                <Input
                                  className="w-100"
                                  size="large"
                                  type="number"
                                  placeholder="Capacity"
                                  value={section.capacity}
                                  onChange={(e) =>
                                    handleSectionChange(
                                      index,
                                      "capacity",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col span={24} md={6}>
                              <div>
                                <Input
                                  className="w-100"
                                  size="large"
                                  placeholder="Description"
                                  value={section.description}
                                  onChange={(e) =>
                                    handleSectionChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col span={24} md={6}>
                              <div className="flex-justify-between">
                                <Checkbox
                                  checked={section.is_active}
                                  onChange={(e) =>
                                    handleSectionChange(
                                      index,
                                      "is_active",
                                      e.target.checked
                                    )
                                  }
                                >
                                  Is Active ?
                                </Checkbox>
                                <p
                                  className="text-danger me-4 fw-6"
                                  onClick={() => handleRemoveSection(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  Remove
                                </p>
                              </div>
                            </Col>
                          </Row>
                        ))}
                        <div className="mt-3 flex justify-center">
                          <Button type="primary" onClick={handleAddSection}>
                            + Add More
                          </Button>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={classData.is_active}
                        onChange={(e) =>
                          setClassData((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {id ? "Update" : "Add Class"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default ClassesForm;
