import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchExpenseTypes } from "../../../libs/redux/features/accounting/expenseTypesSlice";
import {
  fetchAll as fetchExpenses,
  postData,
  updateData,
} from "../../../libs/redux/features/accounting/espenseSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  renderDescription,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import usePageTitle from "../../../components/PageTitle";
import { getData } from "../../../libs/axios/axiosInstance";
import { fetchAll as fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearNewSlice";
import { Link } from "react-router-dom";

const { Option } = Select;
const { Search } = Input;

const Expense = () => {
  usePageTitle("Expenses");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Accounting",
    },
    { title: "Expenses" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const expensesData = useSelector((state) => state.expenses.data);
  const totalItems = useSelector((state) => state.expenses.totalItems);
  const isLoading = useSelector((state) => state.expenses.isLoading);
  const error = useSelector((state) => state.expenses.error);

  const expenseTypes = useSelector((state) => state.expenseType.data);
  const academicYearData = useSelector((state) => state.academicYearsNew.data);

  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    amount: "",
    date: "",
    expense_type: null,
  });

  useEffect(() => {
    if (expenseTypes.length === 0) {
      dispatch(fetchExpenseTypes());
    }
    if (academicYearData.length === 0) {
      dispatch(fetchAcademicYear());
    }
  }, [dispatch, expenseTypes.length, academicYearData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    expense_type: "all",
    date_from: "",
    date_to: "",
    search: "",
    academic_year: "all",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.expense_type !== "all") {
      params.expense_type = filters.expense_type;
    }
    if (filters.academic_year !== "all") {
      params.academic_year = filters.academic_year;
    }
    if (filters.date_from !== "") {
      params.date_from = filters.date_from;
    }
    if (filters.date_to !== "") {
      params.date_to = filters.date_to;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    dispatch(fetchExpenses(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const expenseTypeItem =
      expenseTypes &&
      expenseTypes.find((types) => types.id === item.expense_type)?.name;

    return {
      Serial: index + 1,
      Title: item.title || "N/A",
      Description: item.description || "N/A",
      Amount: item.amount || "N/A",
      Date: item.date || "N/A",
      "Expense Type": expenseTypeItem || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Title",
      "Description",
      "Amount",
      "Date",
      "Expense Type",
    ];
    downloadPDF(expensesData, columns, formatTimeSlotsData, "Expenses Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(expensesData, formatTimeSlotsData, "Expenses Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      title: data.title,
      description: data.description,
      date: data.date ? dayjs(data.date) : null,
      amount: data.amount,
      expense_type: data.expense_type,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => renderDescription(text),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Expense Type",
      dataIndex: "expense_type",
      render: (type) =>
        expenseTypes && expenseTypes.find((item) => item.id === type)?.name,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `accounting/admin/expenses/${record.id}/`,
                fetchExpenses,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      title: "",
      description: "",
      amount: "",
      date: "",
      expense_type: null,
    });
  };

  const handleModalOk = () => {
    const payload = {
      ...modalData,
      date: dayjs(modalData.date).format("YYYY-MM-DD") || null,
      academic_year: 23,
    };
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New expense added successfully!");
            handleModalCancel();
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  const [reportModal, setReportModal] = useState(false);
  const [reportModalData, setReportModalData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const reportModalClick = async () => {
    setReportLoading(true);
    try {
      const response = await getData(
        "accounting/admin/expenses/total-expenses/"
      );
      if (response.status === 200) {
        setReportModalData(response.data);
        setReportLoading(false);
        setReportModal(true);
      }
    } catch (error) {
      console.log("error getting all expenses", error.response);
      setReportLoading(false);
    }
  };

  const handleReportModalCancel = () => {
    setReportModal(false);
    setReportLoading(false);
    setReportModalData({});
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Expenses</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            className="me-3"
            size="large"
            onClick={reportModalClick}
            loading={reportLoading}
          >
            Total Expenses
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              value={filters.expense_type}
              onChange={(value) => handleFilterChange("expense_type", value)}
            >
              <Option value="all">Filter by All Expense Types</Option>
              {expenseTypes && expenseTypes.length > 0
                ? expenseTypes.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              value={filters.academic_year}
              onChange={(value) => handleFilterChange("academic_year", value)}
            >
              <Option value="all">All Academic Years</Option>
              {academicYearData && academicYearData.length > 0
                ? academicYearData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.year}
                    </Option>
                  ))
                : ""}
            </Select>
            <DatePicker
              placeholder="Date From"
              className="w-200"
              value={filters.date_from ? dayjs(filters.date_from) : null}
              onChange={(value) =>
                handleFilterChange(
                  "date_from",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="Date To"
              className="w-200"
              value={filters.date_to ? dayjs(filters.date_to) : null}
              onChange={(value) =>
                handleFilterChange(
                  "date_to",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={expensesData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Expenses</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Title</p>
              {error && error.title ? (
                <p className="text-sm text-danger ms-3">({error.title})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Title"
              required
              value={modalData.title}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
              status={error && error.title ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Amount</p>
              {error && error.amount ? (
                <p className="text-sm text-danger ms-3">({error.amount})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Amount"
              required
              value={modalData.amount}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
              status={error && error.amount ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Date</p>
              {error && error.date ? (
                <p className="text-sm text-danger ms-3">({error.date})</p>
              ) : (
                ""
              )}
            </div>
            <DatePicker
              className="w-100"
              size="large"
              placeholder="Date"
              required
              value={modalData.date}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  date: value,
                }))
              }
              status={error && error.date ? "error" : ""}
            />
          </div>

          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Expense Type</p>
              {error && error.expense_type ? (
                <p className="text-sm text-danger ms-3">
                  ({error.expense_type})
                </p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              placeholder="Select Expense type"
              value={modalData.expense_type}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  expense_type: value,
                }))
              }
              status={error && error.expense_type ? "error" : ""}
            >
              {expenseTypes && expenseTypes.length > 0
                ? expenseTypes.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Description</p>
              {error && error.description ? (
                <p className="text-sm text-danger ms-3">
                  ({error.description})
                </p>
              ) : (
                ""
              )}
            </div>
            <TextArea
              className="w-100"
              autoSize={{ minRows: 2, maxRows: 4 }}
              placeholder="Description"
              required
              value={modalData.description}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              status={error && error.description ? "error" : ""}
            />
          </div>
        </Modal>
      )}

      {reportModal && (
        <Modal
          title={<h2 className="fs-3 mb-3">Total Expenses</h2>}
          open={reportModal}
          onCancel={handleReportModalCancel}
          onOk={handleReportModalCancel}
        >
          {Object.keys(reportModalData).length > 0
            ? Object.entries(reportModalData).map(([key, value]) => (
                <p key={key}>
                  {key.replace(/_/g, " ")}: {value || "Not found"}
                </p>
              ))
            : "No data available"}
        </Modal>
      )}
    </div>
  );
};

export default Expense;
