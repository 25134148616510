import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll,
  postData,
  updateData,
} from "../../../libs/redux/features/accounting/expenseTypesSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import { EyeOutlined, DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import TextArea from "antd/es/input/TextArea";
import BreadBtn from "../../../components/BreadBtn";
import { Link } from "react-router-dom";

const { Option } = Select;
const { Search } = Input;

const ExpenseType = () => {
  usePageTitle("Expense Types");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Accounting",
    },
    { title: "Expense Types" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const expenseTypes = useSelector((state) => state.expenseType.data);
  const totalItems = useSelector((state) => state.expenseType.totalItems);
  const isLoading = useSelector((state) => state.expenseType.isLoading);
  const error = useSelector((state) => state.expenseType.error);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    is_active: "all",
    name: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    if (filters.name !== "") {
      params.name = filters.name;
    }
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: item.name || "N/A",
    Status: item.is_active ? "Active" : "False" || "N/A",
    Description: item.description || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Description", "Status"];
    downloadPDF(
      expenseTypes,
      columns,
      formatTimeSlotsData,
      "Expense  types Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(expenseTypes, formatTimeSlotsData, "Expense types Data");
  };

  const [modalData, setModalData] = useState({
    name: "",
    description: "",
    is_active: true,
  });

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      name: data.name,
      description: data.description,
      is_active: data.is_active,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `accounting/admin/expenses-type/${record.id}/`,
              fetchAll,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `accounting/admin/expenses-type/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      name: "",
      description: "",
      is_active: true,
    });
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New expense type added successfully!");
            handleModalCancel();
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  return (
    <div>
      <BreadBtn
        headerTitle="Expense Types"
        items={breadcrumbitems}
        addButton={true}
        btnClick={() => setAddModal(true)}
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search with name..."
              onSearch={(value) => handleFilterChange("name", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">Filter by All Status</Option>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>

        <Table
          columns={columns}
          dataSource={expenseTypes}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Expense Type</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Name</p>
              {error && error.name ? (
                <p className="text-sm text-danger ms-3">({error.name})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Name"
              required
              value={modalData.name}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              status={error && error.name ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Description</p>
              {error && error.description ? (
                <p className="text-sm text-danger ms-3">
                  ({error.description})
                </p>
              ) : (
                ""
              )}
            </div>
            <TextArea
              className="w-100"
              autoSize={{ minRows: 2, maxRows: 4 }}
              placeholder="Description"
              required
              value={modalData.description}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              status={error && error.description ? "error" : ""}
            />
          </div>
          <div className="my-3">
            <Checkbox
              checked={modalData.is_active}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  is_active: e.target.checked,
                }))
              }
            >
              Is Active ?
            </Checkbox>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ExpenseType;
