import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll,
  postData,
  updateData,
} from "../../../libs/redux/features/accounting/budgetSlice";
import {
  downloadPDF,
  downloadXLS,
  generatePDFReport,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  Modal,
  Pagination,
  Table,
} from "antd";
import DataConversion from "../../../components/DataConversion";
import usePageTitle from "../../../components/PageTitle";
import { getData } from "../../../libs/axios/axiosInstance";
import { Link } from "react-router-dom";
// import ReportTemplate from "../../../components/ReportTemplate";

const Budget = () => {
  usePageTitle("Budgets");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Accounting",
    },
    { title: "Budgets" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const budgetsData = useSelector((state) => state.budgets.data);
  const totalItems = useSelector((state) => state.budgets.totalItems);
  const isLoading = useSelector((state) => state.budgets.isLoading);
  const error = useSelector((state) => state.budgets.error);

  const [modalData, setModalData] = useState({
    category: "",
    allocated_amount: "",
    spent_amount: "",
  });

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Category: item.category || "N/A",
    "Allocated Amount": item.allocated_amount || "N/A",
    "Spent Amount": item.spent_amount || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Category",
      "Allocated Amount",
      "Spent Amount",
      "Academic Year",
    ];
    downloadPDF(budgetsData, columns, formatTimeSlotsData, "Budgets Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(budgetsData, formatTimeSlotsData, "Budgets Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      category: data.category,
      allocated_amount: data.allocated_amount,
      spent_amount: data.spent_amount,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Allocated Amount",
      dataIndex: "allocated_amount",
    },
    {
      title: "Spent Amount",
      dataIndex: "spent_amount",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.category}`,
                dispatch,
                `accounting/admin/budgets/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData({
      category: "",
      allocated_amount: "",
      spent_amount: "",
    });
  };

  const handleModalOk = () => {
    const payload = { ...modalData, academic_year: 23 };
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New budget added successfully!");
            handleModalCancel();
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  const [reportModal, setReportModal] = useState(false);
  const [reportModalData, setReportModalData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const reportModalClick = async () => {
    setReportLoading(true);
    try {
      const response = await getData(
        "accounting/admin/budgets/total-budget-report/"
      );
      if (response.status === 200) {
        setReportModalData(response.data);
        setReportLoading(false);
        setReportModal(true);
      }
    } catch (error) {
      console.log("error getting budget report", error.response);
      setReportLoading(false);
    }
  };

  const handleReportModalCancel = () => {
    setReportModal(false);
    setReportLoading(false);
    setReportModalData({});
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Budgets</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            className="me-3"
            size="large"
            onClick={reportModalClick}
            loading={reportLoading}
          >
            Budget Report
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          {/* <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
          </div> */}
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={budgetsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Budgets Data</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Category</p>
              {error && error.category ? (
                <p className="text-sm text-danger ms-3">({error.category})</p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Category"
              required
              value={modalData.category}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
              status={error && error.category ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Allocated Amount</p>
              {error && error.allocated_amount ? (
                <p className="text-sm text-danger ms-3">
                  ({error.allocated_amount})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Allocated Amount"
              required
              value={modalData.allocated_amount}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  allocated_amount: e.target.value,
                }))
              }
              status={error && error.allocated_amount ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Spent Amount</p>
              {error && error.spent_amount ? (
                <p className="text-sm text-danger ms-3">
                  ({error.spent_amount})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Spent Amount"
              required
              value={modalData.spent_amount}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  spent_amount: e.target.value,
                }))
              }
              status={error && error.spent_amount ? "error" : ""}
            />
          </div>
        </Modal>
      )}
      {/* report modal */}
      {reportModal && (
        <Modal
          title={<h2 className="fs-3 mb-3">Budget Report</h2>}
          open={reportModal}
          // width={840}
          onCancel={handleReportModalCancel}
          onOk={() => generatePDFReport("Budget Report", reportModalData)}
          okText="Download"
        >
          {/* <ReportTemplate reportModalData={reportModalData} title="Budget" /> */}
          <table
            style={{
              marginTop: 30,
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "2px solid #ccc",
                }}
              >
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Field</th>
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(reportModalData).length > 0 ? (
                Object.entries(reportModalData).map(([key, value]) => (
                  <tr key={key}>
                    <td
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        textTransform: "capitalize",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </td>
                    <td style={{ padding: 10, border: "1px solid #ccc" }}>
                      {value}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default Budget;
