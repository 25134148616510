import React, { useEffect, useState } from "react";
import BreadBtn from "../../../components/BreadBtn";
import DataConversion from "../../../components/DataConversion";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import dayjs from "dayjs";
import { fetchAll } from "../../../libs/redux/features/student-info/studentAttendanceSlice";
import { downloadPDF, downloadXLS } from "../../../components/utility";
import { DatePicker, Pagination, Select, Table } from "antd";

const { Option } = Select;

const StudentAttendances = () => {
  usePageTitle("Student Attendances");
  const breadcrumbitems = [
    { title: "Student Data" },
    { title: "Student Attendances" },
  ];

  const dispatch = useDispatch();
  const attendances = useSelector((state) => state.studentAttendance.data);
  const isLoading = useSelector((state) => state.studentAttendance.isLoading);
  const totalItems = useSelector((state) => state.studentAttendance.totalItems);

  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  const [filters, setFilters] = useState({
    class_attended: null,
    section_attended: null,
    date: dayjs().format("YYYY-MM-DD"),
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    if (filters.class_attended) {
      dispatch(fetchSections({ class_level: filters.class_attended }));
    } else {
      dispatch(fetchSections());
    }
  }, [filters.class_attended, dispatch]);

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
      date: filters.date,
    };
    if (filters.class_attended !== null) {
      params.class_attended = filters.class_attended;
    }
    if (filters.section_attended !== null) {
      params.section_attended = filters.section_attended;
    }
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    console.log(item.class_attended);

    const classItem =
      classesData &&
      classesData.find((classEntry) => classEntry.id === item.class_attended)
        ?.name;
    console.log(classItem);

    return {
      Serial: index + 1,
      Student: item.student || "N/A",
      Date: item.date || "N/A",
      Class: classItem,
      Status: item.status || "N/A",
    };
  };

  console.log(attendances);

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Student", "Date", "Class", "Status"];
    downloadPDF(
      attendances,
      columns,
      formatTimeSlotsData,
      "Student Attendance Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(attendances, formatTimeSlotsData, "Student Attendance Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Class",
      dataIndex: "class_attended",
      render: (classId) =>
        classesData && classesData.find((item) => item.id === classId)?.name,
    },
    {
      title: "Section",
      dataIndex: "section_attended",
      render: (sectionId) =>
        sectionsData &&
        sectionsData.find((item) => item.id === sectionId)?.name,
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Student Attendances"
        items={breadcrumbitems}
        addPath="/student-data/student-attendances/add-attendance"
        addTitle="Post Attendance"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("class_attended", value)}
            >
              <Option value="all">All Classes</Option>
              {classesData && classesData.length > 0
                ? classesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) =>
                handleFilterChange("section_attended", value)
              }
            >
              <Option value="all">All Sections</Option>
              {sectionsData && sectionsData.length > 0
                ? sectionsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <DatePicker
              className="w-200"
              placeholder="Start date"
              value={filters.date ? dayjs(filters.date) : null}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  date: date ? date.format("YYYY-MM-DD") : null,
                }))
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={attendances}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default StudentAttendances;
