import React, { useEffect, useState } from "react";
import BreadBtn from "../../../components/BreadBtn";
import { Image, Input, Pagination, Select, Table } from "antd";
import DataConversion from "../../../components/DataConversion";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";

const { Search } = Input;
const { Option } = Select;

const Students = () => {
  const breadcrumbitems = [{ title: "Student Data" }, { title: "Students" }];

  usePageTitle("Students");
  const dispatch = useDispatch();
  const studentData = useSelector((state) => state.students.data);
  const isLoading = useSelector((state) => state.students.isLoaing);
  const totalItems = useSelector((state) => state.students.totalItems);

  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);

  const [filters, setFilters] = useState({
    search: "",
    limit: 10,
    offset: 0,
    school_class: null,
    section: null,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.school_class !== null) {
      params.school_class = filters.school_class;
    }
    if (filters.section !== null) {
      params.section = filters.section;
    }
    dispatch(fetchAll(params));
  }, [dispatch, filters]);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  useEffect(() => {
    if (filters.school_class) {
      dispatch(fetchSections({ class_level: filters.school_class }));
    } else {
      dispatch(fetchSections());
    }
  }, [dispatch, filters.school_class]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "user",
      render: (user) => (
        <div className="flex aic">
          <Image
            src={user.image.image}
            alt={user.image.alt_text}
            style={{
              width: 50,
              height: 50,
              borderRadius: 10,
              marginRight: 10,
              objectFit: "cover",
            }}
          />
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "Student ID",
      dataIndex: "student_id",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, user) => user.user.email,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      render: (_, user) => user.user.mobile,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/student-data/students/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.user.first_name} ${record.user.last_name}`,
                dispatch,
                `student/admin/students/${record.id}/`,
                fetchAll,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: `${item.user.first_name} ${item.user.last_name}` || "N/A",
    Email: item.user.email || "N/A",
    Mobile: item.user.mobile || "N/A",
    "Current Class": item.current_class || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Email", "Mobile", "Current Class"];
    downloadPDF(studentData, columns, formatTimeSlotsData, "Student Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(studentData, formatTimeSlotsData, "Student Attendance Data");
  };

  return (
    <div>
      <BreadBtn
        headerTitle="Students"
        items={breadcrumbitems}
        addPath={`/student-data/students/add-student`}
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search.."
              className="w-200"
              onSearch={(value) => handleFilterChange("search", value)}
              allowClear
              onClear={() => handleFilterChange("search", "")}
            />
            <Select
              showSearch
              className="w-200"
              placeholder="Select Class"
              optionFilterProp="children"
              value={filters.school_class || null}
              onChange={(value) => handleFilterChange("school_class", value)}
              allowClear
              onClear={() => handleFilterChange("school_class", null)}
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Section"
              optionFilterProp="children"
              value={filters.section || null}
              onChange={(value) => handleFilterChange("section", value)}
              allowClear
              onClear={() => handleFilterChange("section", null)}
            >
              {sectionsData &&
                sectionsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={studentData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Students;
