import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchStudents } from "../../../libs/redux/features/student-info/studentsSlice";
import { fetchAll as fetchStructures } from "../../../libs/redux/features/accounting/feesStructureSlice";
import {
  fetchAll as fetchPayments,
  updateData,
  postData,
} from "../../../libs/redux/features/accounting/feesPaymentSlice";
import {
  downloadPDF,
  downloadXLS,
  formatDateTime,
  generatePDFReport,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import DataConversion from "../../../components/DataConversion";
import { getData } from "../../../libs/axios/axiosInstance";
import { Link } from "react-router-dom";

const { Option } = Select;
const { Search } = Input;

const FeesPayment = () => {
  usePageTitle("Fees Payment");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Accounting",
    },
    { title: "Fees Payment" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const feesPaymentsData = useSelector((state) => state.feesPayments.data);
  const totalItems = useSelector((state) => state.feesPayments.totalItems);
  const isLoading = useSelector((state) => state.feesPayments.isLoading);
  const error = useSelector((state) => state.feesPayments.error);

  const studentsData = useSelector((state) => state.students.data);
  const feesStructuresData = useSelector((state) => state.feesStructures.data);

  const [modalData, setModalData] = useState({
    amount_paid: "",
    student: null,
    fee_structure: null,
  });

  useEffect(() => {
    if (studentsData.length === 0) {
      dispatch(fetchStudents());
    }
    if (feesStructuresData.length === 0) {
      dispatch(fetchStructures());
    }
  }, [dispatch, studentsData.length, feesStructuresData.length]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    is_overdue: "all",
    payment_date: "",
    search: "",
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.is_overdue !== "all") {
      params.is_overdue = filters.is_overdue;
    }
    if (filters.payment_date !== "") {
      params.payment_date = filters.payment_date;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    dispatch(fetchPayments(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const studentInfo =
      studentsData && studentsData.find((entry) => entry.id === item.student);
    const feesInfo =
      feesStructuresData &&
      feesStructuresData.find((entry) => entry.id === item.fee_structure)?.name;
    return {
      Serial: index + 1,
      Student: item.student || "N/A",
      "Payment Date": item.payment_date || "N/A",
      "Fee Structure": feesInfo || "N/A",
      "Amount Paid": studentInfo
        ? `${studentInfo.user.first_name} ${studentInfo.user.last_name}`
        : "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "Payment Date",
      "Fee Structure",
      "Amount Paid",
    ];
    downloadPDF(
      feesPaymentsData,
      columns,
      formatTimeSlotsData,
      "Fees Payment Data"
    );
  };

  const handleDownloadXLS = () => {
    downloadXLS(feesPaymentsData, formatTimeSlotsData, "Fees Payment Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      amount_paid: data.amount_paid,
      student: data.student,
      fee_structure: data.fee_structure,
    });
    setId(data.id);
    setAddModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student",
      render: (studentId) => {
        const student =
          studentsData && studentsData.length > 0
            ? studentsData.find((item) => item.id === studentId)
            : {};
        return (
          `${student?.user?.first_name} ${student?.user?.last_name}` ||
          "unknown"
        );
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      render: (date) => formatDateTime(date),
    },
    {
      title: "Fee Structure",
      dataIndex: "fee_structure",
      render: (structureId) => {
        const structure =
          feesStructuresData &&
          feesStructuresData.find((item) => item.id === structureId)?.name;
        return structure || "Unknown";
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.student}`,
                dispatch,
                `accounting/admin/fees/payments/${record.id}/`,
                fetchPayments
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData((prev) => ({
      ...prev,
      amount_paid: "",
    }));
  };

  const handleModalOk = () => {
    const payload = {
      ...modalData,
      academic_year: 23,
    };
    if (!id) {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New Payment added successfully!");
            setModalData((prev) => ({
              ...prev,
              amount_paid: "",
            }));
            setAddModal(false);
          },
        })
      );
    } else {
      dispatch(updateData({ id, payload }));
    }
  };

  const [reportModal, setReportModal] = useState(false);
  const [reportModalData, setReportModalData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const reportModalClick = async () => {
    setReportLoading(true);
    try {
      const response = await getData(
        "accounting/admin/fees/payments/total-fees-report/"
      );
      if (response.status === 200) {
        setReportModalData(response.data);
        setReportLoading(false);
        setReportModal(true);
      }
    } catch (error) {
      console.log("error getting all payment reports", error.response);
      setReportLoading(false);
    }
  };

  const handleReportModalCancel = () => {
    setReportModal(false);
    setReportLoading(false);
    setReportModalData({});
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Fees Payment</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            className="me-3"
            size="large"
            onClick={reportModalClick}
            loading={reportLoading}
          >
            Payment Report
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              value={filters.is_overdue}
              onChange={(value) => handleFilterChange("is_overdue", value)}
            >
              <Option value="all">All Data</Option>
              <Option value={true}>Overdue Data</Option>
              <Option value={false}>Not Overdue</Option>
            </Select>

            <DatePicker
              placeholder="Date From"
              className="w-200"
              value={filters.payment_date ? dayjs(filters.payment_date) : null}
              onChange={(value) =>
                handleFilterChange(
                  "payment_date",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={feesPaymentsData}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>

      {/* add and edit modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Expenses</h2>}
          open={addModal}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Amount Paid</p>
              {error && error.amount_paid ? (
                <p className="text-sm text-danger ms-3">
                  ({error.amount_paid})
                </p>
              ) : (
                ""
              )}
            </div>
            <Input
              className="w-100"
              size="large"
              placeholder="Amount paid"
              required
              value={modalData.amount_paid}
              onChange={(e) =>
                setModalData((prev) => ({
                  ...prev,
                  amount_paid: e.target.value,
                }))
              }
              status={error && error.amount_paid ? "error" : ""}
            />
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Student</p>
              {error && error.student ? (
                <p className="text-sm text-danger ms-3">({error.student})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              placeholder="Select Student"
              showSearch
              optionFilterProp="children"
              value={modalData.student || null}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  student: value,
                }))
              }
              status={error && error.student ? "error" : ""}
            >
              {studentsData && studentsData.length > 0
                ? studentsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.user.first_name} {item.user.last_name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">* Fee Structure</p>
              {error && error.fee_structure ? (
                <p className="text-sm text-danger ms-3">
                  ({error.fee_structure})
                </p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              placeholder="Select Fees Structure"
              value={modalData.fee_structure || null}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  fee_structure: value,
                }))
              }
              status={error && error.fee_structure ? "error" : ""}
            >
              {feesStructuresData && feesStructuresData.length > 0
                ? feesStructuresData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </Modal>
      )}

      {reportModal && (
        <Modal
          title={<h2 className="fs-3 mb-3">All Payments Report</h2>}
          open={reportModal}
          onCancel={handleReportModalCancel}
          onOk={() => generatePDFReport("All payments report", reportModalData)}
        >
          <table
            style={{
              marginTop: 30,
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  borderBottom: "2px solid #ccc",
                }}
              >
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Field</th>
                <th style={{ padding: 10, border: "1px solid #ccc" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(reportModalData).length > 0 ? (
                Object.entries(reportModalData).map(([key, value]) => (
                  <tr key={key}>
                    <td
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        textTransform: "capitalize",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </td>
                    <td style={{ padding: 10, border: "1px solid #ccc" }}>
                      {value}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default FeesPayment;
